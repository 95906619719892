import React, { useEffect, useState } from "react";
import bublBull from "./assets/img/bublbull.png";

const BublBull = () => {
  const [hasDropped, setHasDropped] = useState(false);

  useEffect(() => {
    const bullElement = document.querySelector<HTMLImageElement>(".bubl-bull img");

    if (!bullElement) return;

    
    bullElement.style.transition = "transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55)";
    bullElement.style.transform = "translateY(300px) translateX(0px)";

    setTimeout(() => {
      setHasDropped(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!hasDropped) return;

    const bullElement = document.querySelector<HTMLImageElement>(".bubl-bull img");

    if (!bullElement) return;

    let position = 0; 
    let direction = 1;

    const swing = () => {
      position += direction * 1; 
      bullElement.style.transform = `translateY(300px) translateX(${position}px)`;

      
      if (position >= 10 || position <= -10) {
        direction *= -1;
      }
    };

    const interval = setInterval(swing, 16);

    return () => clearInterval(interval);
  }, [hasDropped]);

  return (
    <div className="bubl-bull-wrap">
      <div className="bubl-bull">
        <img src={bublBull} alt="Bubl Bull" />
      </div>
    </div>
  );
};

export default BublBull;









