import React from "react";
import copy from "./assets/img/copy.png";
import copyHover from "./assets/img/copy-hover.png";



const CopyButton = () => {
  const address = "0x35b59a0d8707764cde2345e4a3acd025b73d3d639308e49df00eff7eec821d46::bubl::BUBL";

  const handleCopy = () => {
    navigator.clipboard.writeText(address)
      .then(() => {
        alert("Address copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className="address-btn-wrap">
      <div className="buy-button" onClick={handleCopy}>
        <img src={copy} alt="Copy" className="default-image" />
        <img src={copyHover} alt="Copy Hover" className="hover-image" />
      </div>
    </div>
  );
};

export default CopyButton;