import React, { FC } from "react";
import { SwapTerminal } from "@interest-protocol/sui-coins-terminal";

const Terminal: FC = () => (
  <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <SwapTerminal
      typeIn="0x2::sui::SUI" 
      projectAddress="0x7407fcd842d6a97e301313444761740959fca58806c20b511de0c4ce49367479" 
      typeOut="0x35b59a0d8707764cde2345e4a3acd025b73d3d639308e49df00eff7eec821d46::bubl::BUBL" 
      slippage="1" 
      interval="10" 
    />
  </div>
);

export default Terminal;
