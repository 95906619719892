import React, { useState } from "react";
import "./App.css";
import CopyButton from "./Copytxt";
import BublBull from "./bublBull";
import Terminal from "./Terminal";
import logo from "./assets/img/bublLG.png"; 
import telegram from "./assets/img/telegram.png"; 
import twitter from "./assets/img/twitter.png"; 
import bubleit from "./assets/img/BUBLEIT.png"; 
import mememachine from "./assets/img/mememachine.png";
import grandbubl from "./assets/img/grandbubl.png";
import telegramHover from "./assets/img/telegram-hover.png";
import twitterHover from "./assets/img/twitter-hover.png";
import letsTry from "./assets/img/letsTry.png";
import letsTryHover from "./assets/img/letsTry-hover.png";
import telegramFooter from "./assets/img/telegramFooter.png";
import telegramFooterHover from "./assets/img/telegramFooter-hover.png";
import BUBL from "./assets/img/BUBL.png";
import buyBtn from  "./assets/img/buy-btn.png";
import buyBtnHover from  "./assets/img/buy-btn-hover.png";




const App = () => {
  const [showTerminal, setShowTerminal] = useState(false); 

  const toggleTerminal = () => {
    setShowTerminal(!showTerminal); 
  };

  return (
    <div className="App">
      <section>
        <div className="header">
        <BublBull />
          <div className="nav-wrap">
            <div className="nav-one">
              <a href="https://dexscreener.com/sui/0x72022f3e27ae2715960de7e6b56d1cf728d7861cbbd582b4edd9a837c777a710" target="_blank" rel="noopener noreferrer">
                CHART
              </a>
              <a href="https://media.bublsui.com/" target="_blank" rel="noopener noreferrer">BUBLMEDIA</a>
            </div>

            <div className="bubl-socials">
              <img className="bubble-logo" src={logo} alt="Bubble Logo" />
              <div className="social-buttons">
              <a href="https://t.me/bublsui" target="_blank" rel="noopener noreferrer" className="social-button telegram">
                <img src={telegram} alt="Telegram" />
                <img src={telegramHover} alt="Telegram" />
              </a>
              
              <a href="https://x.com/bublsui" target="_blank" rel="noopener noreferrer" className="social-button twitter">
                <img src={twitter} alt="Twitter" />
                <img src={twitterHover} alt="Twitter" />
              </a>
              </div>
            </div>

            <div className="nav-two">
              <a href="http://mememachine.bublsui.com" >MEMEMACHINE</a>
              
              <a href="https://7k.ag/trade/swap?from=0x0000000000000000000000000000000000000000000000000000000000000002%3A%3Asui%3A%3ASUI&to=0x35b59a0d8707764cde2345e4a3acd025b73d3d639308e49df00eff7eec821d46%3A%3Abubl%3A%3ABUBL&amountIn=100" target="_blank" rel="noopener noreferrer">DEX</a>
            </div>
          </div>
        </div>

        <div className="bubbleit-wrap">
          <img src={bubleit} alt="Bubble It!" />
        </div>

        <div className="bigspacer"></div>
      </section>

      <section id="2">
        <div className="main">
          <div className="address-menu">
            <span className="coin-address-txt">COIN ADDRESS</span>
            <span className="coin-address">0x35b59a0...eec821d46::bubl::BUBL</span>

            <div className="address-btn-wrap">
            <CopyButton />

            <div className="buy-button" onClick={toggleTerminal}>
              <img src={buyBtn} alt="Buy" className="default-image" />
              <img src={buyBtnHover} alt="Buy Hover" className="hover-image" />
            </div>
          </div>

          <div className="spacer"></div>

          </div>

            <div className="mememachine">
              <div className="h1-wrap">
                <span>OUR PRODUCTS</span>
              </div>
              
                <div className="machine-block">
                  <div className="machine-img">
                    <img src={mememachine} alt="Mememachine" />
                  </div>

                  <div className="machine-txt">
                    <div className="machine-txt-item">
                      FIRE UP THE <div className="gradient-text">MEMEMACHINE</div>
                    </div>

                    <div className="machine-txt-item-second">
                      First Community Hub <br /> Game on Sui
                    </div>

                    <div className="machine-btn-wrap">
                      <div className="try-button">
                        <a href="http://mememachine.bublsui.com" target="_blank" rel="noopener noreferrer">
                          <img src={letsTry} alt="Let's try!" className="default-image" />
                        </a>

                        <a href="http://mememachine.bublsui.com" target="_blank" rel="noopener noreferrer">
                          <img src={letsTryHover} alt="Let's try hover" className="hover-image"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
        </div>
      </section>
      
      <div className="spacer-second"></div>

      <section id="3">
      <div className="footer">
        <div className="ours-cult">
          <img src={grandbubl} alt="Grand Bubl" />

          <div className="ours-cult-wrap">
            <span>JOIN OUR CULT</span>

            <div className="cult-btn">
              <a href="https://t.me/bublsui" target="_blank" rel="noopener noreferrer">
                <img src={telegramFooter} alt="Telegram" className="default-image" />
              </a>

              <a href="https://t.me/bublsui" target="_blank" rel="noopener noreferrer">
                <img src={telegramFooterHover} alt="Telegram Hover" className="hover-image" />
              </a>
            </div>
          </div>
        </div>
        
        <div className="spacer-second"></div>

        <div className="footer-bubl-wrap">
          <img src={BUBL} alt="BUBL" />
        </div>
      </div>

      </section>


      
      
      {showTerminal && (
        <div className="modal-overlay" onClick={toggleTerminal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} 
          >
            <Terminal />
            <button className="close-button" onClick={toggleTerminal}>
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
